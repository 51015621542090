const baseRoute = "/auth"

export const authRoutes = {
    LOGIN: `${baseRoute}/login`,
    SIGNUP:  `${baseRoute}/signup`,
    VERIFY_ACCOUNT:  `${baseRoute}/account-verify`,
    RESET_PASSWORD: `${baseRoute}/reset-password`,
    FORGOT_PASSWORD: `${baseRoute}/forgot-password`,
    DELETE_ACCOUNT: `${baseRoute}/delete-verify`,
}
//auth.ts

import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { WarningAmberOutlined, Google } from "@mui/icons-material";
import {
  Button,
  Card,
  Container,
  Group,
  Select,
  Space,
  Stack,
  Switch,
  Text,
  TextInput,
  useMantineTheme,
} from "@mantine/core";
import { useAppDispatch, useAppSelector } from "src/redux/redux-store/hooks";
import { authService } from "src/services/services/auth/auth.api";
import { ResponseKind } from "src/services/services/MainService";
import { updateAdminSettings, updateDisclaimers } from "src/redux/redux-store/application/appReducer";
import useAdminSettings from "src/hooks/useAdminSettings";
import { FeedbackComponent } from "../common/Feedback";
import useResponsive from "src/hooks/useResponsive";
import { COLORS, FONT_SIZES } from "src/utils/styles";
import { FocusButton } from "../common/Buttons/FocusButton";

interface OtherSettingsListProps {
  handleClick?: (key: string) => void;
}

const OtherSettingsList: FunctionComponent<OtherSettingsListProps> = ({ handleClick }) => {
  const theme = useMantineTheme();
  const dispatch = useAppDispatch();
  const {
    adminSettings,
    enableGDrive,
    loading,
    feedback,
    error,
    updateDisclaimer,
  } = useAdminSettings();

  const [disclaimers, setDisclaimers] = useState(adminSettings?.disclaimers);
  const { backgroundColor } = useResponsive();
  useEffect(() => {
    setDisclaimers(adminSettings?.disclaimers);
  }, [adminSettings?.disclaimers]);

  return (
    <Card w={"100%"} bg={backgroundColor} withBorder my={"md"}>
      <TextInput
        styles={(theme) => ({
          input: {
            marginTop: theme.spacing.xs,
          },
        })}
        placeholder="XXXXX"
        onChange={(event) => setDisclaimers(event.currentTarget.value)}
        value={disclaimers}
        label="Disclaimers"
      />
      <Stack maw={"100%"} my={"md"}>
        <FocusButton 
        icon="save"
        text="Update Disclaimers"
        onClick={() => updateDisclaimer(disclaimers, () => {
          dispatch(updateDisclaimers(disclaimers));
        })}
        />
        <FocusButton 
        icon="paletteOutlined"
        text="Change Logo"
        />
      </Stack>

      <Card withBorder>
        <Group position="apart">
          <Group>
            <WarningAmberOutlined fontSize={"small"} />
            <Text size={"sm"}>Maintainence Notice</Text>
          </Group>
          <Switch readOnly />
        </Group>

          <Group mt={"sm"} position="apart">
            <Group>
              <Google fontSize={"small"} />
              <Text size={"sm"}>File Import From Google Drive</Text>
            </Group>
            <Switch onChange={(e) => enableGDrive(e.currentTarget.checked)} checked={adminSettings.gdrive_enabled} />
          </Group>
      </Card>
      {feedback ? (
        <FeedbackComponent width="100%" error={error} feedback={feedback} />
      ) : null}
    </Card>
  );
};

export default OtherSettingsList;

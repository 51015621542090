import { ActionIcon, Group, Text } from "@mantine/core"
import { FacebookRounded } from "@mui/icons-material"
import { useCallback } from "react"

export const AppStatusCardFooter = () => {
    const goToFacebook = useCallback(() => {
        window.open("https://www.facebook.com/p/Unwildered-100079907851879/")
    }, [])

    return(
        <Group position="apart" mt={5} style={{ minHeight: '24px' }}>
            <ActionIcon 
                size="md"
                variant="transparent"
                onClick={goToFacebook}
                style={{ 
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <FacebookRounded 
                    style={{ 
                        color: "white",
                        width: "20px",
                        height: "20px"
                    }} 
                />
            </ActionIcon>
            <Text style={{ color: "white" }} size={"xs"}>
                &copy; Unwildered. 2024
            </Text>
        </Group>
    )
}

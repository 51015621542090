import {
  Card,
  Center,
  Container,
  ScrollArea,
  Text,
  createStyles,
  useMantineTheme,
  ActionIcon
} from "@mantine/core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import BotMessage from "./BotMessage/BotMessage";
import UserMessage from "./UserMessage";
import { ChatMessage } from "src/interfaces/Chat";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useMediaQuery } from "@mantine/hooks";
import { optionButtons } from "./BotMessage/metadata";
import useResponsive from "src/hooks/useResponsive";
import ChatInput from "./common/Inputs/ChatMessageInput";
import { COLORS } from "src/utils/styles";
import { useChat } from "src/hooks/useChat";
import useViewportHeight from "src/hooks/useViewPortHeight";

interface ChatAreaProps {
  messages: ChatMessage[];
  streaming: boolean;
  tracelessMessages?: ChatMessage[]
  inputMessage: string
  sendMessage: () => void
  handleStopStream: () => void
  disclaimer: string
  setInputMessage: React.Dispatch<React.SetStateAction<string>>
}

interface StyleParams {
  isIPadLandscape: boolean;
}

const useStyles = createStyles((theme, { isIPadLandscape }: StyleParams) => ({
  chatContainer: {
    height: isIPadLandscape ? 'calc(100vh - 160px)' : 'calc(var(--vh, 1vh) * 100 - 60px)',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
  },
  viewport: {
    flex: 1,
    overflowY: 'auto',
    WebkitOverflowScrolling: 'touch',
    padding: theme.spacing.md,
    paddingBottom: '80px', // Make room for input area
    position: 'relative', // Add this to establish a new stacking context
  },
  inputArea: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    padding: theme.spacing.sm,
    backgroundColor: 'inherit',
    borderTop: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]}`,
    zIndex: 2, // Add z-index to ensure it stays above message content
  },
  messageContainer: {
    position: 'relative', // Add this for proper dropdown positioning
    width: '100%',
    '& .mantine-Menu-dropdown': {
      zIndex: 1000,
      position: 'fixed', // Change to fixed positioning
      maxHeight: '200px',
      overflow: 'auto'
    }
  }
}));

const ChatArea: React.FC<ChatAreaProps> = (props) => {
  const { messages, streaming, tracelessMessages, inputMessage, setInputMessage, sendMessage, handleStopStream, disclaimer } = props;
  const viewport: any = useRef<HTMLDivElement>(null);
  const [isAtBottomButtonTrigger, setAtBottomButtonTrigger] = useState(true);
  const [isAtBottom, setIsAtBottom] = useState(true);
  const [showOptions, setShowOptions] = useState(false);
  const { isIPadLandscape } = useResponsive();
  const { classes } = useStyles({ isIPadLandscape });
  const theme = useMantineTheme();
  useViewportHeight()

  useEffect(() => {
   scrollToBottom()
  }, [showOptions])

  useEffect(() => {
    scrollToBottom()
  }, [messages])

  const scrollToBottom = () => {
    if (viewport.current) {
      viewport.current.scrollTo({
        top: viewport.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }

  const onScroll = ({ y }: { y: number }) => {
    if (!viewport.current) {
      return;
    }
    
    // Calculate the difference and check if it's within a threshold
    const bottomThreshold = isIPadLandscape ? 120 : 100; // pixels from the bottom
    const isBottom = viewport.current.scrollHeight - y <= viewport.current.clientHeight + bottomThreshold;
    setAtBottomButtonTrigger(isBottom);

    // For auto-scroll behavior
    const scrollThreshold = isIPadLandscape ? 80 : 20; // pixels from the bottom
    const atBottom = viewport.current.scrollHeight - y <= viewport.current.clientHeight + scrollThreshold;
    setIsAtBottom(atBottom);
  };

  return (
    <div 
      className={classes.chatContainer}
    >
      <ScrollArea
        viewportRef={viewport}
        className={classes.viewport}
        onScrollPositionChange={onScroll}
        type="always"
        scrollbarSize={6}
        scrollHideDelay={0}
      >
        <div className={classes.messageContainer}>
          {messages?.map((item, index) => {
            let isLastMessage = index === messages.length - 1;
            if (item.role == "User") {
              return (
                <UserMessage
                  key={index}
                  message={item.content}
                />
              );
            } else {
              return (
                <BotMessage
                  key={index}
                  message={item.content}
                  index={index}
                  isLatest={isLastMessage}
                  streaming={streaming && isLastMessage}
                  toggleOptions={() => setShowOptions(!showOptions)}
                  closeOptions={() => setShowOptions(false)}
                  showOptions={showOptions}
                />
              );
            }
          })}
          {tracelessMessages?.map((item, index) => {
            let isLastMessage = index === messages.length - 1;
            if (item.role == "User") {
              return (
                <UserMessage
                  key={index}
                  traceless
                  message={item.content}
                />
              );
            } else {
              return (
                <BotMessage
                  key={index}
                  traceless
                  message={item.content}
                  index={index}
                  isLatest={isLastMessage}
                  streaming={streaming && isLastMessage}
                />
              );
            }
          })}
        </div>
      </ScrollArea>
      {!isAtBottomButtonTrigger && (
        <div className="absolute inset-x-0 bottom-24 flex justify-center">
          <ActionIcon
            onClick={scrollToBottom}
            className="z-10 shadow-md rounded-full"
            size="lg"
            sx={(theme) => ({
              backgroundColor: '#535457',
              color: 'white',
              '&:hover': {
                backgroundColor: '#535457',
              }
            })}
          >
            <ArrowDownwardIcon style={{ color: 'white' }} />
          </ActionIcon>
        </div>
      )}
      <div className={classes.inputArea}>
        <div className="w-full">
          <ChatInput
            streaming={streaming}
            onStopStream={handleStopStream}
            inputMessage={inputMessage}
            setInputMessage={setInputMessage}
            onSend={sendMessage}
            disabled={streaming || !inputMessage}
          />
          <div className='flex justify-center mt-1'>
            <Text style={{
              textAlign: "center", 
              fontSize: isIPadLandscape ? 12 : 11,
              color: theme.colorScheme == "dark" ? COLORS.border : 'black'
            }}>
              Disclaimer: {disclaimer}
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ChatArea };

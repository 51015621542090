import {
  ActionIcon,
  Box,
  Button,
  Card,
  Center,
  Group,
  Text,
  createStyles,
  rem,
} from "@mantine/core";
import { FacebookRounded } from "@mui/icons-material";
import { AppStatusCardFooter } from "./StatusCardFooter";

export const AdminStatusCard = () => {
  return (
    <Card
      p={"xs"}
      mb={"xs"}
      w={"100%"}
      withBorder
      style={{
        backgroundColor: "#AA2C76",
        minHeight: "90px",
        maxHeight: "120px",
        overflow: "visible",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
      }}
    >
      <div>
        <Center mt={2}>
          <Box>
            <Text style={{ color: "white" }} fw={800} size={12}>
              Admin Portal
            </Text>
          </Box>
        </Center>
      </div>

      <AppStatusCardFooter />
    </Card>
  );
};

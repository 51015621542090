import styled from "@emotion/styled";

interface ThemeProps {
  theme: {
    backgroundColor: string;
  };
}

// assuming the parent container is also styled using styled-components
export const NavBarParentContainer = styled.div<ThemeProps>`
  background-color: ${(props) => props.theme.backgroundColor || 'white'};
  display: flex;
  height: 100%;
  justify-content: center; // This centers all child elements horizontally
  width: 100%; // Ensure full width if necessary
`;

export const NavBarContainer = styled.div<ThemeProps>`
background-color: ${(props) => props.theme.backgroundColor || 'white'};
width: 99%;
height: 100%;
padding-right: 1rem;
padding-left: 1rem;
display: flex;
align-items: center;
border-radius: 0 0 0.8125rem 0.8125rem; 
// Consistent with the outer's border-radius in larger screens  

@media (max-width: 992px) {
  width: 100%;
  height: 100%;
  border-radius: 0 0 0 0; 
  // Additional styling for larger screens can be placed here if needed
}
`;

//useauth
// import { useEffect, useState } from "react";
// import { ResponseKind } from "src/services/services/MainService";
// import { authService } from "src/services/services/auth/auth.api";
// import { getToken, setToken } from "src/utils/storage";

// export const useAuth = () => {
//   const [isLoggedIn, setIsLoggedIn] = useState(false) 
//   const [loading, setLoading] = useState(true);
  
//   useEffect(() => {
//     validateToken()
//   }, [])

//   const validateToken = async () => {
//     const access_token = await getToken();

//     try{
//       if (access_token) {
//         if (new Date(access_token?.expires_at) < new Date()) {
//           const refreshToken = await authService.getRefreshToken(
//             access_token.refresh_token
//           );
  
//           if (refreshToken.kind == ResponseKind.OK) {
//             if (refreshToken.data) setToken(refreshToken.data);
//             setIsLoggedIn(true)
//           }
//         }else{
//           setIsLoggedIn(true)
//         }
//       }
//     }catch(e){

//     }finally{
//       setLoading(false)
//     }

//   };

//   return {
//     loggedIn: isLoggedIn,
//     loading: loading
//   };
// };

import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { UserRole } from 'src/enums/User';
import { UserToken } from 'src/interfaces';
import { clearRole, getRole, getToken } from 'src/utils/storage';

interface AuthContextType {
  loading: boolean,
  token: UserToken | null;
  role: UserRole | null;
  login: (newToken: UserToken) => void;
  logout: () => void;
  checkRole: () => void
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [token, setToken] = useState<UserToken | null>(null);
  const [role, setRole] = useState<UserRole | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    validateToken()
  }, []);

  const validateToken = async () => {
    const globalToken = await getToken()
    try{
      if(globalToken){
        login(globalToken)
      }else{
        logout()
      }
    }catch(e){

    }finally{
      setLoading(false)
    }
  }
 

  const checkRole = async () => {
    const role = await getRole()
      if(role){
        setRole(role)
      }
  }

  const login = (newToken: UserToken) => {
    setToken(newToken);
  };

  const logout = () => {
    setToken(null);
    setRole(null)
    clearRole()
  };

  return (
    <AuthContext.Provider value={{ token, login, logout, loading, role, checkRole}}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};

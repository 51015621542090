//sidebar
import {
  ActionIcon,
  Box,
  Button,
  Card,
  Center,
  Container,
  FileButton,
  Group,
  Image,
  List,
  Loader,
  LoadingOverlay,
  MantineTheme,
  Modal,
  Navbar,
  Stack,
  Text,
  createStyles,
  rem,
  useMantineTheme,
} from "@mantine/core";
import {
  Info,
  Facebook,
  FacebookRounded,
  Google,
  StarsTwoTone,
} from "@mui/icons-material";
import UploadDocs from "./UploadDocs";
import { useDocuments } from "src/hooks/useDocuments";
import { useAppSelector } from "src/redux/redux-store/hooks";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DocumentContainerType } from "src/enums/Documents";

import FailedFilesDrawer from "./FailedFilesDrawer";
import UploadFilesDrawer from "./UploadFilesDrawer";
import { ErrorMessage } from "./common/ErrorMessage/ErrorMessage";
import { COLORS, FONT_SIZES } from "src/utils/styles";
import { useNotifications } from "src/hooks/useNotifications";
import { butttonStylesNoIcon } from "./common/GlobalStyles";
import Icon from "./common/Icons/Icons/Icon";
import { userRoutes } from "src/navigators/routes/user";
import { paymentService } from "src/services/services/payment/payment.api";
import { ResponseKind } from "src/services/services/MainService";
import BaseTable from "./Tables/BaseTable";
import { UserRole } from "src/enums/User";
import { UserStatusCard } from "./Cards/UserStatusCard";
import { AdminStatusCard } from "./Cards/AdminStatusCard";
import { appInsights } from "src/ApplicationInsights";
import useViewportHeight from "src/hooks/useViewPortHeight";
import useResponsive from "src/hooks/useResponsive";

interface SideBarProps {
  opened: boolean;
}

interface StyleParams {
  isIPadLandscape: boolean;
}

const useStyles = createStyles((theme, { isIPadLandscape }: StyleParams) => ({
  parentContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.colorScheme === "dark" ? "black" : "white",
  },
  navbar: {
    flex: 1,
    minHeight: 0,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing.sm,
    backgroundColor: theme.colorScheme === "dark" ? "black" : theme.white,
  },
  navbarMain: {
    flex: 1,
    minHeight: 0,
    overflowY: "auto",
    paddingRight: theme.spacing.sm,
    paddingBottom: theme.spacing.xl,
  },
  navbarFooter: {
    marginTop: "auto",
    paddingTop: theme.spacing.xs,
    paddingBottom: isIPadLandscape ? theme.spacing.xl : theme.spacing.xs,
    position: "sticky",
    bottom: 0,
    backgroundColor: theme.colorScheme === "dark" ? "black" : theme.white,
    zIndex: 1000,
  },
  button: {
    borderRadius: "0.25rem",
    fontSize: "0.75rem",
    height: rem(30),
    borderWidth: 0.5,
  },
}));

const Sidebar = (props: SideBarProps) => {
  const { opened } = props;
  const theme = useMantineTheme();
  const { isIPadLandscape } = useResponsive();
  const { classes } = useStyles({ isIPadLandscape });
  const clientStarted = useNotifications()?.started;
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user);
  useViewportHeight();
  const trial_days = user.trial_days;
  const [paymentUrl, setPaymentUrl] = useState("");

  const {
    files,
    loading,
    deleteFiles,
    downloadFile,
    uploadFiles,
    setUploadedFiles,
    selectedFiles,
    deleteSingleFile,
    uploadQueue,
    setSelectedFiles,
    error,
    dismissError,
    clearQueue,
    failFiles,
    uploadDriveFile,
    getDocs,
  } = useDocuments({ container: DocumentContainerType.document });

  const navigateToPayments = useCallback(async () => {
    if (user.paid) {
      const response = await paymentService.createPortalSession();

      if (response.kind == ResponseKind.OK && response.data) {
        setPaymentUrl(response.data);
        return response.data;
      }
    }
    navigate(userRoutes.PAYMENT);
  }, [navigate, user]);

  const handleButtonClick = async () => {
    const url = await navigateToPayments();
    if (url) {
      window.location.href = url;
    }
  };

  const goToFAQs = useCallback(() => {
    window.open("https://www.unwildered.co.uk/how-it-works");
  }, []);

  const goToAboutUs = useCallback(() => {
    window.open("https://www.unwildered.co.uk/about");
  }, []);

  return (
    <Navbar
      hiddenBreakpoint={992}
      withBorder={false}
      px={"xs"}
      className={classes.parentContainer}
      hidden={!opened}
      width={{
        md: 350,
      }}
    >
      <div className={classes.navbar}>
        <ErrorMessage error={error} handleClose={dismissError} />

        <UploadFilesDrawer onPressClose={clearQueue} files={uploadQueue} />

        <Navbar.Section px={"xs"}>
          <Text my={"sm"} fw={600} style={{ fontSize: FONT_SIZES.h1 }}>
            My Documents
          </Text>
          <Card
            p={"0.5rem"}
            w={"100%"}
            withBorder
            style={{ backgroundColor: theme.colors.blue[1] }}
            mx={0}
          >
            <Group spacing="xs" noWrap={true}>
              <Icon name="info" size="1rem" color="black" />
              <Text style={{ color: "black", fontSize: FONT_SIZES.text, lineHeight: 1.4 }} fw={500}>
                Caira can read and see. Upload <strong>evidence</strong>,
                screenshots & documents.
              </Text>
            </Group>
          </Card>
        </Navbar.Section>
        {<LoadingOverlay visible={loading || !clientStarted} />}
        <Navbar.Section grow className={classes.navbarMain}>
          <UploadDocs
            setSelectedFiles={setSelectedFiles}
            uploadedFiles={uploadFiles}
            setUploadedFiles={setUploadedFiles}
            deleteSingleFile={deleteSingleFile}
            width={"90%"}
            onRefresh={getDocs}
            selectedFiles={selectedFiles}
            uploadDriveFile={uploadDriveFile}
            onPressDelete={deleteFiles}
            onPressDownload={downloadFile}
            files={files}
          />
        </Navbar.Section>

        <Navbar.Section className={classes.navbarFooter}>
          <Center>
            <Stack w="95%" spacing="xs">
              <Stack spacing="xs">
                <Button onClick={goToFAQs} styles={butttonStylesNoIcon}>
                  <Text align="center" size={FONT_SIZES.text}>
                    How it works
                  </Text>
                </Button>
                <Button onClick={goToAboutUs} styles={butttonStylesNoIcon}>
                  <Text size={FONT_SIZES.text}>Contact Us</Text>
                </Button>
              </Stack>
              <Box>
                {user.role == UserRole.user ? (
                  <UserStatusCard
                    paid={user?.paid || false}
                    handlePayment={handleButtonClick}
                    trialDays={user.trial_days}
                  />
                ) : (
                  <AdminStatusCard />
                )}
              </Box>
            </Stack>
          </Center>
        </Navbar.Section>
      </div>
    </Navbar>
  );
};

export default Sidebar;

//index
import React, { ReactElement, useState } from 'react';
import { ExpandableListI, Item } from './types';
import { COLORS, FONT_SIZES } from "src/utils/styles";

const ListItem = (props: Item) => {
    const {index, isActive, onClick, children, label} = props

    return (
        <div className={`p-2 ${isActive ? 'rounded-lg bg-theme-color-1 text-white' : 'cursor-pointer'}`}>
            <div className="flex justify-between rounded-xl items-center p-2" onClick={() => onClick(index)}>
                <span className="text-left font-semibold flex-grow" style={{ fontSize: FONT_SIZES.h2 }}>{label}</span>
                <svg className={`w-4 h-4 transform transition-transform ${isActive ? 'rotate-180' : ''}`} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
            </div>
            <div className={`overflow-hidden ${isActive ? 'max-h-2000' : 'max-h-0'}`}>
                {children}
            </div>
        </div>
    );
};

const ExpandableList = (list: ExpandableListI) => {
    const {items} = list
    const [activeIndex, setActiveIndex] = useState<number | null>(0);

    const handleClick = (index: number) => {
        setActiveIndex(prevIndex => prevIndex === index ? null : index);
    };

    return (
        <div>
            {items.map((item, index) => (
                <ListItem
                    label={item.label}
                    key={index}
                    index={index}
                    isActive={index === activeIndex}
                    onClick={handleClick}>
                    {item.renderContent}
                </ListItem>
            ))}
        </div>
    );
};


export default ExpandableList;
//documents.ts
export enum FileType {
  csv = "csv",
  pdf = "pdf",
  txt = "txt",
  docx = "docx",
  png = "png",
  jpg = "jpg",
  jpeg = "jpeg",
  webp = "webp",
}

export enum DocumentContainerType {
  document = "container-consumer",
  knowledgebase = "container-knowledgebase"
}

//api.ts
/**
 * This Api class lets you define an API endpoint and methods to request
 * data and process it.
 *
 * See the [Backend API Integration](https://github.com/infinitered/ignite/blob/master/docs/Backend-API-Integration.md)
 * documentation for more details.
 */
import {
  ApiOkResponse,
  ApiResponse, // @demo remove-current-line
  ApisauceInstance,
  create,
} from "apisauce";
import {
  ErrorKind,
  GeneralApiProblem,
  getGeneralApiProblem,
} from "./apiProblem"; // @demo remove-current-line
import {
  ApiConfig, // @demo remove-current-line
  ResponseKind,
  ResponseType,
} from "./api.types";

import Config from "../../../config/config";
import { clearRole, clearToken, getToken, load, setToken } from "src/utils/storage";
import { token } from "src/enums/User";
import { UserToken } from "src/interfaces";
import { useNavigate } from "react-router-dom";
import { authRoutes } from "src/navigators/routes/auth";
/**
 * Configuring the apisauce instance.
 */
export const DEFAULT_API_CONFIG: ApiConfig = {
  url: Config.API_URL ? Config.API_URL : '',
  timeout: 1000000,
};

export enum RequestContentType {
  JSON = "application/json",
  URL_ENCODED = "application/x-www-form-urlencoded",
  MULTI_FORM = "multipart/form-data",
}

/**
 * Manages all requests to the API. You can use this class to build out
 * various requests that you need to call from your backend API.
 */
export class Api {
  apisauce: ApisauceInstance;
  config: ApiConfig;

  /**
   * Set up our API instance. Keep this lightweight!
   */
  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    this.config = config;
    this.apisauce = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
    });
    this.apisauce.axiosInstance.interceptors.request.use(
      async (config: any) => {
        if (config.url.includes('/refresh') || config.url.includes('blob.core')) {
          return config;
        }

        try {
          const access_token = await getToken();
          if (access_token) {
            const todaysDate = new Date();
            const tokenExpiry = new Date(access_token?.expires_at);

            if (tokenExpiry.getTime() > todaysDate.getTime()) {

              config.headers.Authorization = `Bearer ${access_token?.access_token}`;
            } else {
                const refreshToken = await this.apisauce.post<UserToken>(
                  `${Config.AUTH_URL}/refresh`,
                  undefined,
                  {headers: {'refresh-token': `${access_token?.refresh_token}` }}
                );
                if (refreshToken.ok) {
                  await clearToken()
                  if (refreshToken.data) setToken(refreshToken.data);
                  config.headers.Authorization = `Bearer ${refreshToken?.data?.access_token}`;
                }else{
                  await clearToken()
                  clearRole()
                  const event = new Event('logout');
                  window.dispatchEvent(event);
                }
            } 
          }

          return config;
        } catch (err) {
          console.log(err);
          return config;
        }
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    this.apisauce.axiosInstance.interceptors.response.use(
        response => response,
        error => {
          const status = error.response ? error.response.status : null;
          
          if (status === 401) {
            clearToken()
            clearRole()
            const event = new Event('logout');
            window.dispatchEvent(event);

          } else if (status === 404) {
            // Handle not found errors
          } else {
            // Handle other errors
          }
          
          return Promise.reject(error);
        }
    );
  }

  async getResponse<T>(
    response: ApiResponse<T>,
    hideError?: boolean
  ): Promise<ResponseType<T>> {
    if (!response.ok) {
      const problem = getGeneralApiProblem(response);

      if (problem.kind == ErrorKind.REJECTION_ERROR) {
        // alert(
        //   problem?.errors &&
        //     problem?.errors?.data &&
        //     problem?.errors?.data?.detail
        //     ? problem?.errors?.data?.detail
        //     : "Oops something goes wrong"
        // );
        return {
          kind: ErrorKind.REJECTION_ERROR,
          errors: problem?.errors?.data?.detail,
        };
      } else {
        // alert("Oops something went wrong");
        return {
          kind: ErrorKind.NOT_FOUND_ERROR,
          errors: "Oops something went wrong",
        };
      }
    }

    const res: any = response.data;

    return { kind: ResponseKind.OK, data: res };
  }
}

// Singleton instance of the API for convenience
export const api = new Api();

//useviewportheight
import { useEffect } from 'react';

const useViewportHeight = () => {
  useEffect(() => {
    const setVhProperty = () => {
      // Add a small delay for iOS to finish any UI adjustments
      setTimeout(() => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      }, 100);
    };

    // Set the value on load
    setVhProperty();

    // Set the value on resize and orientation change
    window.addEventListener('load', setVhProperty);
    window.addEventListener('resize', setVhProperty);
    window.addEventListener('orientationchange', setVhProperty);
    
    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('resize', setVhProperty);
      window.removeEventListener('load', setVhProperty);
      window.removeEventListener('orientationchange', setVhProperty);
    };
  }, []);
};

export default useViewportHeight;

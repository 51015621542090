import { UserRole, role } from "src/enums/User";
import { User } from "src/interfaces";
import { dateDiffInDays } from "src/utils/helpers";

export const tranformUser = (user: User): User => {
    if(user.role == UserRole.admin){
        return{
            name: user.name,
            email: user.email,
            paid: true,
            plan: 'six-month',
            id: user.id,
            role: user.role,
            trial_expired: false
        }
    }
    let trial_expired;
    let trial_days;
    if(user.trial_expiry){
     trial_expired = new Date(user.trial_expiry).getTime() < new Date().getTime()
     if(!trial_expired){
        trial_days = dateDiffInDays(new Date(),new Date(user.trial_expiry))  
     }}

    return{
        name: user.name,
        email: user.email,
        paid: user.trial_expiry ? user.paid : true,
        trial_expired: user.trial_expiry ? trial_expired : false,
        plan: user.trial_expiry ? user.plan : 'Six Month',
        trial_days: trial_days || 0,
        role: user.role,
        id: user.id
    }
}

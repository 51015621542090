//auth.api.ts
import Config from "../../../config/config"
import { api, Api } from "../MainService/api"
import { ErrorKind } from "../MainService/apiProblem"
import { User, UserBase, UserToken } from "src/interfaces"
import { AdminAppSettings, AppSettings } from "src/interfaces/AppSettings"

const AUTH_URL = Config.USER_URL
const ADMIN_URL = Config.ADMIN_URL

export class AuthApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  
  }

  async signUp(name: string, email: string, password: string) {
    try {
      const response = await this.api.apisauce.post<UserBase>(`${AUTH_URL}/register`, {
        name: name,
        email: email,
        password: password,
        role: 0
      })
      let data = await this.api.getResponse(response)

      return data
    } catch (e) {

      // alert("You entered some bad data")
      return { kind: ErrorKind.BAD_DATA_ERROR, errors: "Bad Data"}
    }
  }

  async logIn(email: string, password: string) {
    try {
      const params = new URLSearchParams();
      params.append('username', email);
      params.append('password', password);
      const response = await this.api.apisauce.post<UserToken>(`${Config.AUTH_URL}/token`, 
      params,
      {headers: { 'content-type': 'application/x-www-form-urlencoded' }}
      )
      
      let data = await this.api.getResponse(response)

      return data
    } catch (e) {

      // alert("You entered some bad data")
      return { kind: ErrorKind.BAD_DATA_ERROR, errors: "Bad Data"}
    }
  }

  async forgotPassword(email: string) {
    try {
      const response = await this.api.apisauce.post<UserToken>(`${AUTH_URL}/forgot-password`, 
      {email: email},
      )
      
      let data = await this.api.getResponse(response)

      return data
    } catch (e) {

      // alert("You entered some bad data")
      return { kind: ErrorKind.BAD_DATA_ERROR, errors: "Bad Data"}
    }
  }

  async getRefreshToken(refresh_token: string) {
    try {
      const response = await this.api.apisauce.post<UserToken>(`${Config.AUTH_URL}/refresh`, undefined,
      {headers: {'refresh-token': `${refresh_token}` }}
      )
      let data = await this.api.getResponse(response)

      return data
    } catch (e) {

      // alert("You entered some bad data")
      return { kind: ErrorKind.BAD_DATA_ERROR, errors: "Bad Data"}
    }
  }

  async getPubSubToken() {
    try {
      const response = await this.api.apisauce.get<{token: string}>(`${AUTH_URL}/pubsub-token`, undefined)
      let data = await this.api.getResponse(response)

      return data
    } catch (e) {

      // alert("You entered some bad data")
      return { kind: ErrorKind.BAD_DATA_ERROR, errors: "Bad Data"}
    }
  }

  async verifyEmail(token: string, email: string) {
    try {
      const response = await this.api.apisauce.post<UserBase>(`${AUTH_URL}/verify`, {
        token: token,
        email: email
      })
      let data = await this.api.getResponse(response)

      return data
    } catch (e) {
      
      // alert("Your link is bad")
      return { kind: ErrorKind.BAD_DATA_ERROR, errors: "Bad Data"}
    }
  }

  async resetPassword(token: string, email: string, password: string) {
    try {
      const response = await this.api.apisauce.put<UserBase>(`${AUTH_URL}/reset-password`, {
        token: token,
        email: email,
        password: password
      })
      let data = await this.api.getResponse(response)

      return data
    } catch (e) {
      
      // alert("Your link is bad")
      return { kind: ErrorKind.BAD_DATA_ERROR, errors: "Bad Data"}
    }
  }

  async getUserData(){
    try {
      const response = await this.api.apisauce.get<User>(
        `${AUTH_URL}/me`,
      )
      let data = await this.api.getResponse(response, true)
      return data
    } catch (e) {
      console.log(e)
      return { kind: ErrorKind.BAD_DATA_ERROR }
    }
  }

  async createPaymentSession(){
    try {
      const response = await this.api.apisauce.get<User>(
        `${AUTH_URL}/me`,
      )
      let data = await this.api.getResponse(response, true)
      return data
    } catch (e) {
      console.log(e)
      return { kind: ErrorKind.BAD_DATA_ERROR }
    }
  }

  async getAppInfo(){
    try {

      const response = await this.api.apisauce.get<AppSettings>(
        `${AUTH_URL}/app-info`,
        undefined,
     
      )
      let data = await this.api.getResponse(response, true)
      return data
    } catch (e) {
      console.log(e)
      return { kind: ErrorKind.BAD_DATA_ERROR }
    }
  }

  async getAdminConfig(){
    try {
      const response = await this.api.apisauce.get<AdminAppSettings>(
        `${ADMIN_URL}/config`,
     
      )
      
      let data = await this.api.getResponse(response, true)
      return data
    } catch (e) {
      console.log(e)
      return { kind: ErrorKind.BAD_DATA_ERROR }
    }
  }

  async deleteAccount(token: string, email: string){
    try {
      const response = await this.api.apisauce.post(
        `${AUTH_URL}/delete`,
        { token: token,
          email: email
        }
      )
      let data = await this.api.getResponse(response, true)
      return data
    } catch (e) {
      console.log(e)
      return { kind: ErrorKind.BAD_DATA_ERROR }
    }
  }

  async requestDeleteAccount(){
    try {
      const response = await this.api.apisauce.post(
        `${AUTH_URL}/request-delete`,
        undefined
      )
      let data = await this.api.getResponse(response, true)
      return data
    } catch (e) {
      console.log(e)
      return { kind: ErrorKind.BAD_DATA_ERROR }
    }
  }

  async signOut(){
    try {
      const response = await this.api.apisauce.get(
        `${AUTH_URL}/logout`,
      )
      let data = await this.api.getResponse(response, true)
      return data
    } catch (e) {
      console.log(e)
      return { kind: ErrorKind.BAD_DATA_ERROR }
    }
  }
}

export const authService = new AuthApi(api)

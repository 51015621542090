import { ActionIcon, Card, Checkbox, Divider, Pagination, Text, TextInput, ThemeIcon, useMantineTheme } from "@mantine/core";
import { DeleteOutline, Download, RefreshOutlined } from "@mui/icons-material";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { confirmDeletionModal } from "src/components/common/ConfirmationModal";
import { IconButton } from "src/components/common/Buttons/IconButton";
import Icon from "src/components/common/Icons/Icons/Icon";
import { COLORS } from "src/utils/styles";
import { colors } from "@mui/material";
import useResponsive from "src/hooks/useResponsive";
import { useTheme } from "@emotion/react";
import { Documents } from "src/interfaces/Documents";

interface Data {
  name: string;
  id: number;
}

const TableRow = ({
  item,
  selectedData,
  key,
  setSelectedData,
  deleteSingleFile,
  onPressDownload
}: {
  key: number,
  item: Documents;
  deleteSingleFile: (name: string) => void;
  onPressDownload: (name: string) => void;
  selectedData: Documents[];
  setSelectedData: (data: Documents[]) => void | undefined;
}) => {
  const isSelected = selectedData.includes(item);
  return (
    <div className="grid grid-cols-10 h-10 items-center px-2">
      <div className="col-span-1">
        <Checkbox
          size={"xs"}
          className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
          value={key}
          checked={isSelected}
          onChange={(e) =>
            setSelectedData(
              e.target.checked
                ? [...selectedData, item]
                : selectedData.filter((p) => p !== item)
            )
          }
        />
      </div>
      <div className="col-span-7"><Text size={"xs"} fw={"600"} style={{maxWidth: "150px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",}}>{item.filename}</Text></div>
      <div className="col-span-1">
        <ActionIcon
        onClick={() => onPressDownload(item.filename)}
        >
          <Download fontSize={"small"} />
        </ActionIcon>
      </div>
      <div className="col-span-1">
        <ActionIcon
          onClick={() => deleteSingleFile(item.filename)}
        >
          <DeleteOutline fontSize={"small"} />
        </ActionIcon>
      </div>
    </div>
  );
};


interface ScrollableTableProps {
  data: Documents[]
  selectedData: Documents[]
  setSelectedData: React.Dispatch<React.SetStateAction<Documents[]>>;
  onRefresh: () => void
  onSingleDelete: (name: string) => void
  onDownload: (name: string) => void
  onDeleteFiles: () => void
 }


export const ScrollableTable = (props: ScrollableTableProps) => {
  const {data, selectedData, setSelectedData, onRefresh, onDeleteFiles, onDownload, onSingleDelete} = props
  const checkbox = useRef<HTMLInputElement | null>(null);
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const theme = useMantineTheme()

  const [query, setQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const filteredData = query
    ? data.filter((item) =>
        item.filename.toLowerCase().includes(query.toLowerCase())
      )
    : data;

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const currentData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  useLayoutEffect(() => {
    const isIndeterminate =
      selectedData.length > 0 && selectedData.length < data.length;
    setChecked(selectedData.length === data.length);
    setIndeterminate(isIndeterminate);
    if (checkbox.current) checkbox.current.indeterminate = isIndeterminate;
  }, [selectedData, data.length]);

  const toggleAll = () => {
    if(setSelectedData){
      setSelectedData(checked || indeterminate ? [] : data);
      setChecked(!checked && !indeterminate);
      setIndeterminate(false);
    }
  };

  return (
    <>
      <Card p={0} withBorder className="h-52 overflow-y-scroll flex flex-col my-4 rounded-lg">
        <div className={`sticky top-0 flex-col flex py-2 z-40 ${theme.colorScheme == "dark" ? "bg-black" : "bg-white"} px-2`}>
        
          <div className="grid grid-cols-10 h-full items-center">
            <div className="col-span-1">
            <Checkbox
              size={"xs"}
              className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              ref={checkbox}
              checked={checked}
              onChange={toggleAll}
            />
            </div>
       
            <div className="col-span-3">{selectedData.length > 0 ? (
                 <div className="flex items-center">
                    <ActionIcon onClick={onDeleteFiles}><ThemeIcon variant="light"><Icon name="trash" size="1rem" /> </ThemeIcon></ActionIcon> 
               </div>
            ) : (
              <div className="flex items-center">
                <Text size={"sm"} fw={600}>File Name</Text>
              </div>
            )}</div>
            <div className="col-span-5"><div className="flex justify-between items-center">
            <TextInput
              placeholder="Search files..."
              value={query}
              size="xs"
              className="w-[80%] rounded-lg"
              onChange={(event) => setQuery(event.currentTarget.value)}
            />
          </div></div>
            <div className="col-span-1">
              <ActionIcon onClick={onRefresh}>
               <ThemeIcon variant="light" ><Icon name="refresh" size="1rem" /> </ThemeIcon>
              </ActionIcon>
            </div>
          </div>
        </div>
        {currentData.length > 0 ? currentData.map((item, index) => (
          <div>

          <TableRow
            deleteSingleFile={onSingleDelete}
            onPressDownload={onDownload}
            key={index}
            item={item}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
          />
          <Divider />
          </div>
        )) : <div className="flex flex-1 justify-center items-center py-6">
           <Text fw={500} style={{ fontSize: "0.875rem", lineHeight: 1.5, maxWidth: "90%" }}>
             Upload documents for better responses. The limit has been increased to 500k characters per pdf.
           </Text>
         </div>}
      </Card>
      <div className=" w-full flex justify-center items-center"><Pagination total={totalPages} onChange={setCurrentPage} value={currentPage} color="dark" size="sm" radius="md" withControls={false} withEdges /></div>
    </>
  );
};

//use responsive 
import { useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { COLORS } from "src/utils/styles";

const useResponsive = (query?: string) => {
    const isMobile = useMediaQuery(query ? query : "(max-width: 767px)");
    const isMobileMediumAndSmall = useMediaQuery(query ? query : "(max-width: 425px)");
    const isMobileLarge = useMediaQuery(query ? query : "(min-width: 425px) and (max-width: 767px)");
    const isTablet = useMediaQuery(query ? query : "(min-width: 768px) and (max-width: 1024px)");
    const isDesktop = useMediaQuery(query ? query : "(min-width: 1025px)");
    
    // iPad specific detection
    const isIPadMini = useMediaQuery("(width: 744px) and (height: 1133px), (width: 1133px) and (height: 744px)");
    const isIPadAir = useMediaQuery("(width: 820px) and (height: 1180px), (width: 1180px) and (height: 820px)");
    const isIPadPro11 = useMediaQuery("(width: 834px) and (height: 1194px), (width: 1194px) and (height: 834px)");
    const isIPadPro12 = useMediaQuery("(width: 1024px) and (height: 1366px), (width: 1366px) and (height: 1024px)");
    
    // Combined iPad detection
    const isIPad = isIPadMini || isIPadAir || isIPadPro11 || isIPadPro12;
    const isIPadLandscape = useMediaQuery("(orientation: landscape)") && isIPad;
    
    const theme = useMantineTheme();
    const backgroundColor = theme.colorScheme == "dark" ? 'black' : theme.white;
    const backgroundColorExterior = theme.colorScheme == "dark" ? theme.colors.dark[7] : COLORS.bg;

    return {
        isMobile,
        backgroundColor,
        backgroundColorExterior,
        isMobileLarge,
        isMobileMediumAndSmall,
        isTablet,
        isDesktop,
        isIPad,
        isIPadLandscape,
        isIPadPro: isIPadPro11 || isIPadPro12 // Larger iPads might need different styling
    }
}

export default useResponsive

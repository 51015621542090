//usenotifications
import { WebPubSubClient } from "@azure/web-pubsub-client";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { useAppSelector } from "src/redux/redux-store/hooks";
import { ResponseKind } from "src/services/services/MainService";
import { authService } from "src/services/services/auth/auth.api";
import { useAuth } from "./useAuth";
import Config from "src/config/config";

const baseUrl = `${Config.WEB_PUB_SUB_URL}/client/hubs/notification?access_token=`

interface NotificationContextI {
  error: string;
  started: boolean;
  client: WebPubSubClient | null;
  subscribe: (access_token: string) => void;
  unsubscribe: () => void;
  setToken: React.Dispatch<React.SetStateAction<string>>;
}

interface NotificationsProviderProps {
  children: ReactNode;
  url: string;
}

const { setLogLevel } = require("@azure/logger");

setLogLevel("verbose");

const NotificationContext = createContext<NotificationContextI | undefined>(
  undefined
);

export const NotificationsProvider = (props: NotificationsProviderProps) => {
  const { children } = props;
  const [client, setClient] = useState<null | WebPubSubClient>(null);
  const [token, setToken] = useState("");
  const [error, setError] = useState<string>("");
  const [started, setStarted] = useState(false);
  const loggedIn = useAppSelector((state) => state.user.email);
  const appToken = useAuth().token
  const [connectAttempts, setConnectAttemps] = useState(0);

  useEffect(() => {
    if (!started && loggedIn && appToken) {
      subscribe()      
      return;
    }
    if (!loggedIn && started) {
      unsubscribe();
    }
  }, [loggedIn, started, appToken]);

  const subscribe = async () => {
    try {
      const webPubSubClient = new WebPubSubClient(
        {
          getClientAccessUrl: async () => {
            const response = await authService.getPubSubToken();

            return response.kind == ResponseKind.OK
              ? baseUrl + response.data?.token
              : "";
          },
        },
        { autoReconnect: true }
      );
      const startClient = await webPubSubClient.start().then(() => {
        webPubSubClient.on("connected", (e) => {
          setClient(webPubSubClient);
          setStarted(true);
          console.log(`Client connected on `, e?.userId);
        });
      });

      return;
    } catch (e) {
      console.log("e", e);
      setError("Something went wrong, please refresh your page");
    }
  };

  const unsubscribe = () => {
    if (client) {
      client.stop();

      client.on("disconnected", (e) => {
        setStarted(false);
        console.log(`Disconnected client`);
      });
    }
  };

  return (
    <NotificationContext.Provider
      value={{ client, subscribe, unsubscribe, setToken, error, started }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotifications = () => {
  const context = useContext(NotificationContext);

  if (context === undefined) {
    console.log("useNotfication must be used within a Protected Route");
    return;
  }

  return context;
};

//background container
import styled from "@emotion/styled";

interface ThemeProps {
  theme: {
    backgroundColor: string;
  };
}

export const StyledContainerInset = styled.div<ThemeProps>`
  background-color: ${(props) => props.theme.backgroundColor || 'white'};
  height: calc(100% - 60px);
  width: 100%;
  border-radius: 0.8125rem 0.8125rem 0 0;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media (max-width: 992px) {
    border-radius: 0;
  }
`;

export const ChatStyledContainerInset = styled.div<ThemeProps>`
  background-color: ${(props) => props.theme.backgroundColor || 'white'};
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 0.8125rem 0.8125rem 0 0;
  margin: 0;
  padding: 0;
  overflow: hidden;
  
  @media (max-width: 992px) {
    border-radius: 0;
  }
`;

//documents.api.ts 
import Config from "../../../config/config"
import { api, Api } from "../MainService/api";
import { ErrorKind, GeneralApiProblem } from "../MainService/apiProblem";
import { UserBase, UserToken } from "src/interfaces";
import {
  AzureUpload,
  DeleteFile,
  DownloadDoc,
  FileList,
  FileValidate,
  UploadFile,
} from "./documents.api.types";
import { DocumentContainerType } from "src/enums/Documents";

const DOCUMENTS_URL = Config.DOCUMENTS_URL;
const ADMIN_URL = Config.ADMIN_URL;
const AZURE_URL = Config.AZURE_STORAGE_URL;

export class DocumentsApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async uploadDoc(
    files: File[],
    email: string,
    azure_token: string,
    container?: DocumentContainerType
  ) {
    try {
      let failedFiles: File[] = [];
      if (!container) {
        container = DocumentContainerType.document;
      }

      // Use Promise.all to handle concurrent uploads and wait for all to complete
      const uploadPromises = files.map(async (file) => {
        // Sanitize filename - replace non-ASCII characters with ASCII equivalents
        const sanitizedName = file.name.replace(/[^\x00-\x7F]/g, '-')
          .replace(/\s+/g, '_')  // Replace spaces with underscores
          .replace(/[^a-zA-Z0-9._-]/g, '-'); // Replace other special chars with hyphens

        const config = {
          headers: {
            "x-ms-blob-type": "BlockBlob",
            "Content-Type": file.type,
          },
        };
        try {
          const response = await this.api.apisauce.put(
            `${AZURE_URL}/${container}/${email}/${sanitizedName}?${azure_token}&timeout=60`,
            file,
            config
          );

          if (!response.ok) {
            console.log(`File ${file.name} was not uploaded`);
            failedFiles.push(file);
          }
        } catch (error) {
          console.error(`Error uploading file ${file.name}:`, error);
          failedFiles.push(file);
        }
      });

      // Wait for all uploads to complete
      await Promise.all(uploadPromises);
      return failedFiles;

    } catch (e) {
      console.error("File uploading failed:", e);
      return Promise.reject("Bad api call");
    }
  }

  async getUserToken() {
    try {
      const response = await this.api.apisauce.get<{
        azure_storage_token: string;
      }>(`${DOCUMENTS_URL}/get-azure-storage-token`);
      let data = await this.api.getResponse(response, true);
      return data;
    } catch (e) {
      console.log(e);
      return { kind: ErrorKind.BAD_DATA_ERROR };
    }
  }

  async uploadGdrive(gDriveLink: string) {
    try {
      const response = await this.api.apisauce.post<UploadFile>(
        `${DOCUMENTS_URL}/upload-gdrive?gdrivelink=${gDriveLink}`
      );
      let data = await this.api.getResponse(response);

      return data;
    } catch (e) {
      // alert("You entered some bad data")
      return { kind: ErrorKind.BAD_DATA_ERROR, errors: "Bad Data" };
    }
  }

  async validateFiles(fileNames: string[]) {
    try {
      const response = await this.api.apisauce.post<{ files: FileValidate[]}>(
        `${DOCUMENTS_URL}/validate-documents`,
        {
          file_names: fileNames,
        }
      );
      let data = await this.api.getResponse(response);

      return data;
    } catch (e) {
      // alert("You entered some bad data")
      return { kind: ErrorKind.BAD_DATA_ERROR, errors: "Bad Data" };
    }
  }

  async downloadDoc(id: string) {
    try {
      const response = await this.api.apisauce.get<DownloadDoc>(
        `${DOCUMENTS_URL}/download/${id}`
      );

      let data = await this.api.getResponse(response);

      return data;
    } catch (e) {
      // alert("You entered some bad data")
      return { kind: ErrorKind.BAD_DATA_ERROR, errors: "Bad Data" };
    }
  }

  async deleteDocs(files: string[]) {
    try {
      const response = await this.api.apisauce.post<DeleteFile>(
        `${DOCUMENTS_URL}/delete-multiple`,
        {
          file_names: files,
        }
      );
      let data = await this.api.getResponse(response);
      
      return data;
    } catch (e) {
      // alert("You entered some bad data")
      return { kind: ErrorKind.BAD_DATA_ERROR, errors: "Bad Data" };
    }
  }

  async getDocuments() {
    try {
      const response = await this.api.apisauce.get<FileList>(
        `${DOCUMENTS_URL}/list`
      );
      let data = await this.api.getResponse(response, true);
      return data;
    } catch (e) {
      console.log(e);
      return { kind: ErrorKind.BAD_DATA_ERROR };
    }
  }
}
export const documentService = new DocumentsApi(api);

import { useState, useEffect } from 'react';

const RotatingPrompt = () => {
  const prompts = [
    "Caira, write a draft.",
    "What evidence could strengthen my case?",
    "huh? wat does the below email mean???",
    "Make the response longer?",
    "Challenge my or the other party's statement.",
    "caira, Caira, CAIRA!!1 I'm overwhelmed :( ",
    "Have I missed anything important? Speak freely.",
    "Combine the below into a single email.",
    "What could be the worst case scenario?",
    "Caira, explain it to me like I'm 7 years old.",
    "Caira, what are the legal issues here?",
    "Rewrite this but with an amicable tone.",
    "Caira, have I missed anything?",
    "I don't understand the below letter.",
    "What evidence could swing the outcome?",
    "How could I protect my interest?",
    "Caira, you are amazing :) "
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [fade, setFade] = useState(true);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setFade(false); // Start fade out
      
      // Wait for fade out, then change text
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % prompts.length);
        setFade(true); // Start fade in
      }, 500); // Half of the transition duration
      
    }, 3000); // Change every 3 seconds

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="w-full text-center h-8"> {/* Fixed height to prevent layout shift */}
      <p 
        className={`
          text-lg italic
          transition-opacity duration-500 ease-in-out
          ${fade ? 'opacity-75' : 'opacity-0'}
        `}
      >
        {prompts[currentIndex]}
      </p>
    </div>
  );
};

export default RotatingPrompt;

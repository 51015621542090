import { FunctionComponent, useCallback, useEffect, useState } from "react";
import {  ChatArea } from "src/components/Chat";
import { useAppDispatch, useAppSelector } from "src/redux/redux-store/hooks";
import {
  Card,
  Center,
  Container,
  LoadingOverlay,
  Text,
  createStyles,
  useMantineTheme,
} from "@mantine/core";
import { StyledContainer } from "src/components/common/StyledComponents/Container";
import { UserRole } from "src/enums/User";
import { Navigate, useNavigate } from "react-router-dom";
import { getRole } from "src/utils/storage";
import {
  handleUpdateAppSettings,
  handleUserUpdate,
  stopStream,
} from "src/redux/redux-store";
import { ChatStyledContainerInset, StyledContainerInset } from "src/components/common/StyledComponents/BackgroundContainer";
import { COLORS } from "src/utils/styles";
import { Notifications } from "@mantine/notifications";
import { useMediaQuery } from "@mantine/hooks";
import ChatInput from "src/components/common/Inputs/ChatMessageInput";
import { useChat } from "src/hooks/useChat";
import { useNotifications } from "src/hooks/useNotifications";
import { ErrorMessage } from "src/components/common/ErrorMessage/ErrorMessage";
import { PubSubNotificationTypes } from "src/enums/Notifications";
import useResponsive from "src/hooks/useResponsive";
import { userRoutes } from "src/navigators/routes/user";
import { AppSettings } from "src/interfaces/AppSettings";


const useStyles = createStyles((theme) => ({
  parentContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    height: "100%",
    overflow: "hidden",
  },
  chatContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  }
}));

const Dashboard = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const client = useNotifications()?.client
  const user = useAppSelector(state => state.user)
  const appSettings = useAppSelector((state) => state.app).appSettings;
  const {backgroundColor, backgroundColorExterior, isMobile } = useResponsive()
  
  const {inputMessage, setInputMessage, getMessages, sendMessage, streaming, chatLoading, messages, tracelessMessages} = useChat()

  const checkRole = async () => {
    const role = await getRole();

    if (role == UserRole.admin) {
      return navigate("/admin/", { replace: true });
    }
  };


  useEffect(() => {
    if(client){
      client.on("server-message", (data) => {
        if(data.message.data){
          const message = JSON.parse(data.message.data.toString());

          if(message.event_type == PubSubNotificationTypes.appInfoUpdate){
            dispatch(handleUpdateAppSettings())
          }
        }
      })
    }
  }, [client]);

  useEffect(() => {
    checkRole();
    getAllData();
  }, [])

  const checkFreeTrial = () => {
    if(user.trial_expired && !user.paid){
      navigate(userRoutes.PAYMENT)
    }
  }
  
  const getAllData = useCallback(() => {
    dispatch(handleUserUpdate());
    dispatch(handleUpdateAppSettings((settings: AppSettings) => {
      getMessages(settings.greeting_message)
    }));
    checkFreeTrial();
  }, [dispatch]);


  const handleStopStream = useCallback(() => {
    dispatch(stopStream(true));
  }, [dispatch]);

  return (
    <StyledContainer theme={{ backgroundColor: backgroundColorExterior }}>
      <div className={classes.parentContainer}>
        <ChatStyledContainerInset theme={{backgroundColor: backgroundColor}}>
          <div className={classes.chatContainer}>
            <Notifications
              position={isMobile ? "top-center" : "bottom-right"}
              limit={isMobile ? 1 : 5}
            />
            <LoadingOverlay visible={chatLoading} />
            <ChatArea
              messages={messages}
              streaming={streaming}
              tracelessMessages={tracelessMessages}
              inputMessage={inputMessage}
              setInputMessage={setInputMessage}
              sendMessage={sendMessage}
              handleStopStream={handleStopStream}
              disclaimer={appSettings?.disclaimers}
            />
          </div>
        </ChatStyledContainerInset>
      </div>
    </StyledContainer>
  );
};

export default Dashboard;

//chat.ts
export enum Sender {
  system = "system",
  user = "user",
}

export enum ChatMode {
  NA = 0,
  Simplify = 1,
  Elaborate = 2,
  Get_Legal_Precedent = 3
}

import { ActionIcon, Loader, Menu, Tooltip, useMantineTheme, createStyles } from "@mantine/core";
import { useClipboard } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { useCallback, useEffect, useRef, useState } from "react";
import ReactMarkdown from 'react-markdown';
import { ChatMode } from "src/enums/Chat";
import { optionButtons } from "./metadata";
import { useChat } from "src/hooks/useChat";
import { COLORS } from "src/utils/styles";
import Icon from "../common/Icons/Icons/Icon";

interface BotMessage {
    message: string
    index: number
    isLatest?: boolean
    streaming?: boolean
    traceless?: boolean
    toggleOptions?: () => void
    closeOptions?: () => void
    showOptions?: boolean
}

const useStyles = createStyles((theme) => ({
  messageContent: {
    '& .section': {
      borderLeft: `3px solid ${COLORS.primary}`,
      paddingLeft: theme.spacing.sm,
      marginTop: theme.spacing.xs,
      marginBottom: theme.spacing.xs,
      backgroundColor: theme.colorScheme === 'dark' 
        ? theme.fn.rgba(COLORS.primary, 0.05)
        : theme.fn.rgba(COLORS.primary, 0.03)
    },
    '& ul, & ol': {
      paddingLeft: theme.spacing.md,
      margin: `${theme.spacing.xs} 0`,
    },
    '& li': {
      marginBottom: theme.spacing.xs,
    },
    '& .key-term': {
      backgroundColor: theme.colorScheme === 'dark'
        ? theme.fn.rgba(COLORS.primary, 0.15)
        : theme.fn.rgba(COLORS.primary, 0.08),
      padding: '0 4px',
      borderRadius: 4
    }
  }
}));

const BotMessage = (props: BotMessage) => {
    const {streaming, isLatest, traceless, toggleOptions = () => {}, closeOptions = () => {}, showOptions} = props
    const {sendMessageToBackend} = useChat()
    const theme = useMantineTheme()
    const { classes } = useStyles();
    const [latest, setLatest] = useState(isLatest)
    const lastMessageRef = useRef<HTMLDivElement | null>(null)
    const clipboard = useClipboard({ timeout: 500 });
    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
    const buttonRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      setLatest(isLatest)
    }, [isLatest])

    useEffect(() => {
      if (buttonRef.current && showOptions) {
        const rect = buttonRef.current.getBoundingClientRect();
        setMenuPosition({
          x: rect.left,
          y: rect.bottom + window.scrollY
        });
      }
    }, [showOptions]);

    const handleCopy = (textToCopy: string) => {
      // Remove Markdown formatting
      const plainText = textToCopy
        .replace(/\*\*(.*?)\*\*/g, '$1')  // Remove bold
        .replace(/\*(.*?)\*/g, '$1')      // Remove italic
        .replace(/`(.*?)`/g, '$1')        // Remove inline code
        .replace(/^```[\s\S]*?```$/gm, '') // Remove code blocks
        .replace(/^\s*[-*+]\s/gm, '')     // Remove list markers
        .replace(/^\s*\d+\.\s/gm, '')     // Remove numbered list markers
        .replace(/\[(.*?)\]\(.*?\)/g, '$1') // Remove links, keep text
        .trim();

      clipboard.copy(plainText);
      showNotification({
        message: 'Text copied to clipboard',
        color: "grape",
      });
    };

    if(streaming && (!props?.message || props.message.length === 0)){
      return (<div className="inline-block text-center bg-chat-dialogue rounded-tr-lg rounded-b-lg p-2.5 mb-5 mx-auto">
        <Loader size={"md"} variant="dots" color="gray"/>
      </div>)
    }

    return (
      <div className={latest ? 
        "flex flex-col items-start justify-start space-y-4 pt-0 pb-5 pl-0 mq800:max-w-[90%]" :
        "flex flex-col items-start justify-start space-y-4 pt-0 pb-0 pl-0 mq800:max-w-[90%]"}>
        <div className="rounded flex flex-col items-start justify-start pt-4 px-0 pb-0 box-border">
          <div className={`rounded-tl-none rounded-tr-lg rounded-b-lg ${traceless ? "bg-white-theme-button-border" : theme.colorScheme == "dark" ? "bg-color-theme-for-msg-dialogue" : " bg-chat-dialogue"} flex flex-col items-start justify-start py-0 px-0`}>
            <div className={`text-sm xxs:text-xs p-4 py-2 font-1470x705-default-poppins-regular-16 text-left inline-block ${classes.messageContent}`}
              style={{ color: theme.colorScheme === 'dark' ? theme.white : theme.black }}
            >
              <ReactMarkdown>{props.message}</ReactMarkdown>
            </div>
          </div>

          <div className="h-12 flex relative flex-col items-start justify-start pt-4 pb-0 pl-0 box-border min-w-[150px] mq450:pr-5 mq450:box-border">
            <div className="flex relative flex-row items-center justify-start pl-0 box-border min-w-[150px] mq450:pr-5 mq450:box-border">
              <div className="rounded-t rounded-b-none flex flex-row items-center justify-start p-2">
                <ActionIcon size={"1rem"} onClick={() => handleCopy(props.message)}><Icon name="copy" size="1rem" /></ActionIcon>
              </div>
              {(!streaming && latest && props?.index != 0 && !traceless) && (
                <div ref={buttonRef} className="flex flex-col items-center justify-center px-1 box-border">
                  <Menu
                    opened={showOptions}
                    onClose={closeOptions}
                    position="bottom"
                    offset={5}
                    shadow="md"
                    width={200}
                    styles={{
                      dropdown: {
                        position: 'fixed',
                        left: `${menuPosition.x}px`,
                        top: `${menuPosition.y}px`,
                        zIndex: 1000,
                        maxHeight: '200px',
                        overflow: 'auto'
                      },
                      item: {
                        '&:hover': {
                          color: COLORS.primary,
                          backgroundColor: theme.fn.rgba(COLORS.primary, 0.05)
                        }
                      }
                    }}
                  >
                    <Menu.Target>
                      <div onClick={toggleOptions} style={{ cursor: 'pointer' }}>
                        {theme.colorScheme == "light" ? 
                          <img
                            className="h-[22px] w-[22.1px] relative object-cover"
                            loading="lazy"
                            alt=""
                            src="/femaleheadsilhouettebeaurifulgirlwomanvector31305525removebgpreview-1@2x.png"
                          /> : 
                          <img
                            className="h-[22px] w-[22.1px] relative object-cover"
                            loading="lazy"
                            alt=""
                            src="/female-head-silhouette-beauriful-girl-woman-vector-31305525-removebg-preview.png"
                          />
                        }
                      </div>
                    </Menu.Target>
                    <Menu.Dropdown>
                      {optionButtons.map((option) => (
                        <Menu.Item
                          key={option.key}
                          onClick={() => {
                            sendMessageToBackend(props.message, option.mode);
                            closeOptions();
                          }}
                          styles={(theme) => ({
                            item: {
                              '&:hover': {
                                backgroundColor: '#aa2c76',
                                color: 'white'
                              }
                            }
                          })}
                        >
                          <Tooltip label={option.tooltip}>
                            <span>{option.label}</span>
                          </Tooltip>
                        </Menu.Item>
                      ))}
                    </Menu.Dropdown>
                  </Menu>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
}

export default BotMessage;
